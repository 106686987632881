import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import {
  Container,
  Divider,
  Header,
  Grid,
  Image,
  Segment,
} from "semantic-ui-react";

import asimovLogo from "../../data/img/partners/asimov-logo.svg";
import cortevaLogo from "../../data/img/partners/corteva-logo.svg";
import dowLogo from "../../data/img/partners/dow-logo.svg";
import twistLogo from "../../data/img/partners/twist-logo.svg";
import { withLayout } from "../components/Layout";



const CareersPage = () => {

  return (
    <Segment padded="very">
      <Container>
        <Header as="h1" textAlign="center">
          Careers
        </Header>

        <Divider hidden />
        <Divider hidden />
        <div style={{ paddingBottom: 150, paddingLeft: 50, paddingRight: 50 }}>
          <p style={{ textAlign: 'center' }}>
            Lattice Automation is not hiring at this time.
          </p>
          <p style={{ textAlign: 'center', paddingTop: 20 }}>
            (Update August 2024): Any hiring related emails from us are not legitimate at this time. Lattice Automation will always follow up with a voice or video call for all hiring processes.
          </p>
        </div>

      </Container>
    </Segment>
  );
};

export default withLayout(CareersPage);
